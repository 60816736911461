import React from 'react'

export default function LandingPage() {
  return (
    <div className="min-h-screen bg-[#0a0a1e] text-white font"> 
      <header className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <div className="w-8 h-8 bg-white rounded-full"></div>
          {/* <a href="#news" className="text-sm hover:underline">
            News
          </a>
          <span className="text-sm text-gray-400">→ Announcing our $500m fundraise</span> */}
        </div>
        <a href="#careers" className="text-sm hover:underline">
          Careers
        </a>
      </header>

      <main className="container mx-auto px-4 py-20">
        <div className="max-w-2xl">
          <h1 className="text-5xl font-bold mb-8">
            CoverAI is building next generation AI for lawyers.
          </h1>
          {/* <a
            href="#learn-more"
            className="inline-flex items-center bg-[#8080ff] text-black px-6 py-3 rounded-full font-medium hover:bg-[#9090ff] transition-colors"
          >
            Find out more
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 h-4 w-4"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </a> */}
        </div>
      </main>

      <footer className="container mx-auto px-4 py-20 grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h2 className="text-2xl font-bold mb-4">Our new foundation model</h2>
          <p className="text-gray-400">
            A model built specifically for the challenges of modern software development
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">
            Your <span className="text-[#8080ff]">code</span> & data
          </h2>
          <p className="text-gray-400">
            Fine-tune our model on how your business writes software, using your codebase
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Your custom model</h2>
          <p className="text-gray-400">
            Your proprietary model that continuously learns how your team writes code
          </p>
        </div>
      </footer>
    </div>
  )
}