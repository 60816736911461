import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MainPage, MainPage2, MainPage3, MainPage4, MainPage5 } from './components/MainPage.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage5 />} />
        <Route path="/v2" element={<MainPage2 />} />
        <Route path="/v3" element={<MainPage3 />} />
        <Route path="/v4" element={<MainPage4 />} />
        <Route path="/v5" element={<MainPage />} />
      </Routes>
    </Router>
  );
}

export default App;
