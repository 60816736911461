import React, { useEffect, useState } from 'react';

const CenteredWaterDropGrid = () => {
    const [drops, setDrops] = useState([]);
    const gridWidth = 20;  // Changed from gridSize to gridWidth
    const gridHeight = 10; // Added gridHeight for a rectangular grid
    const cellSize = 40;
    const maxRadius = cellSize / 0.5;
    const animationDuration = 6000;
  
    useEffect(() => {
      const centerX = gridWidth / 0.75;
      const centerY = gridHeight / 1.5;
  
      const newDrops = [];
      for (let y = 0; y < gridHeight; y++) {
        for (let x = 0; x < gridWidth; x++) {
        const distance = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2));
        const delay = distance * 100; // Delay based on distance from center
        newDrops.push({ x, y, delay });
      }
    }
    setDrops(newDrops);
  }, []);

  return (
    <svg width={gridWidth * cellSize} height={gridHeight * cellSize} className="overflow-visible">
      {drops.map((drop, index) => (
        <circle
          key={index}
          cx={drop.x * cellSize + cellSize / 2}
          cy={drop.y * cellSize + cellSize / 2}
          r={0}
          fill="none"
          stroke="rgba(255, 255, 255, 0.1)"
          strokeWidth="1"
        >
          <animate
            attributeName="r"
            from="0"
            to={maxRadius}
            dur={`${animationDuration}ms`}
            begin={`${drop.delay}ms`}
            fill="remove"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur={`${animationDuration}ms`}
            begin={`${drop.delay}ms`}
            fill="remove"
            repeatCount="indefinite"
          />
        </circle>
      ))}
    </svg>
  );
};

export default CenteredWaterDropGrid;