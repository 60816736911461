import React from 'react';
import logoTwo from '../assets/CoverAILogoWhiteTwo.svg';
import write from '../assets/write.png';
import lock from '../assets/lock.png';
import page from '../assets/page.png';
import CenteredWaterDropGrid from './CenteredWaterDropGrid';

export default function PoolsideSectionThree() {
  return (
    <div className="min-h-screen bg-[#1c1c1c] text-white font-lato p-8 relative overflow-hidden">
      {/* Add WaterDropGrid as a background */}
      <div className="hidden sm:block absolute my-24 mx-24 left-0 lg:right-8 lg:left-auto">
        <CenteredWaterDropGrid />
      </div>

        <header className="ml-4 mt-8 mb-16">
            <img src={logoTwo} alt="CoverAI Logo" className="w-12" />
        </header>

        <main className="mx-16 mx-auto">
          <h1 className="text-5xl font-light mb">
            CoverAI.
          </h1>
          <h2 className="text-5xl font-light bg-gradient-to-t from-[#7E7E7E] to-white text-transparent bg-clip-text">
            Safely Unlock AI Within Your Business
          </h2>

          {/* <h2 className="text-5xl font-light bg-gradient-to-t from-gray-400 to-white text-transparent bg-clip-text mb-2">
            Safely <span className="bg-white text-black mx border-white text-nowrap">Unlock AI</span> Within Your Business
          </h2> */}
          {/* <h2 className="text-5xl font-bold text-gray-400 mb-2">
            Safely <span className="bg-gradient-to-t from-gray-800 to-white text-transparent bg-clip-text mx border-white text-nowrap">Unlock AI</span> Within Your Business
          </h2> */}
          <p className="text-xl mt-2 mb-24">
            CoverAI provides custom and general purpose AI solutions for legal and professional service firms.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-24 mb-16">
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <img src={lock} alt="Lock Icon" className="w-4 h-4" />
              <h3 className="text-xl font-bold">Full Compliance.</h3>
            </div>
            <p className="text-gray-400">
              We handle the most sensitive data, regardless of your industry's regulations. Our AI solutions ensure GDPR and HIPAA compliance, keeping data secure on-premises and meeting ISO/IEC 27001 and SOC 2 standards.
            </p>
          </div>
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <img src={page} alt="Page Icon" className="w-4 h-4" />
              <h3 className="text-xl font-bold">Custom Training.</h3>
            </div>
            <p className="text-gray-400">
              We provide custom, industry-specific AI training, ensuring precise, useful results. Traditional models often lack value when untrained, as their broad context window fails to capture specialized nuances unique to your business.
            </p>
          </div>
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <img src={write} alt="Write Icon" className="w-4 h-4" />
              <h3 className="text-xl font-bold">Effortless Use.</h3>
            </div>
            <p className="text-gray-400">
              We simplify AI adoption for your team, enhancing productivity intuitively. Our optimized LLMs blend robust features with the user-friendly experience of familiar models, delivering an experience your team will actually look forward to.
            </p>
          </div>
          </div>

      </main>
    </div>
  );
}